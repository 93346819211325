<template>
  <TopPanel />

  <div id="main-container" class="container-xl container-fluid p-0">
    <div class="row">
      <div class="col m-4">

        <div class="row mb-5">
          <div class="col offset-sm-5 offset-md-4 offset-lg-3">
            <div class="display-3 text-primary">{{ title }}</div>
          </div>
        </div>

        <ContentDoc />
      </div>
    </div>

  </div>
</template>

<script setup>
  const { page } = useContent();
  // title is computed, or otherwise it won't get
  // updated when page changes but layout doesn't
  const title = computed(() => page.value.title);
</script>